
// $(function () {
//     $('.carousel.carousel-multi-item .carousel-item').each(function(){
//         var next = $(this).next();
//         if (!next.length) {
//             next = $(this).siblings(':first');
//         }
//         next.children(':first-child').clone().appendTo($(this));
//
//         for (var i=0;i<4;i++) {
//             next=next.next();
//             if (!next.length) {
//                 next=$(this).siblings(':first');
//             }
//             next.children(':first-child').clone().appendTo($(this));
//         }
//     });
//
// });

$(document).ready(function() {

    $('.feedback-slider').slick({
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnFocus: false,
        pauseOnHover: true,
        pauseOnDotsHover: false,
        slidesToShow: 3,
        slidesToScroll: 1,

        responsive: [

            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    });

});
